import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="RGPD e ISO 27001" description="RGPD e ISO 27001 - expanda seus negócios usando a automação de marketing multicanal baseada em dados e vença a concorrência!" />
        <Hero title="Estamos em conformidade com o RGPD e a ISO 27001" button="false" anim="false" gdpr="yes" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                  <h3>RGPD e a ExpertSender</h3>
                  <p>O Regulamento Geral sobre a Proteção de Dados (RGPD) é uma lei europeia de privacidade que entrou em vigor em 25 de maio de 2018. O RGPD regula como indivíduos e organizações podem coletar, usar e conservar dados pessoais, o que afeta a forma como nossos clientes usam a plataforma ExpertSender.</p>
                  <h4>A quem o RGPD se aplica?</h4>
                  <p id="dpo">O RGPD se aplica a TODAS as organizações que coletam e processam dados pessoais de indivíduos residentes na União Europeia, independentemente da localização física da empresa. Isso significa que, se você tem clientes da União Europeia, mas sua empresa não está localizada na própria União Europeia, mesmo assim você precisará aderir aos novos regulamentos. Você pode ler uma visão geral mais detalhada sobre <a href="https://expertsender.com/blog/does-gdpr-apply-to-you/" target="_blank" rel="noopener noreferrer">como o RGPD se aplica a você aqui</a>.
                  </p>
                  <h4>Agente de Proteção de Dados (DPO)</h4>
                  <p class="mar-b-3"> Se você deseja exercer seus direitos e saber se processamos seus dados pessoais, entre em contato com nosso Agente de Proteção de Dados (Michał Kidoń) através deste endereço de e-mail: dpo@expertsender.com</p>
                  <h3>ISO 27001 e a ExpertSender</h3>
                  <p> A ExpertSender optou por obter a certificação ISO 27001 para garantir que nossa organização estivesse preparada para gerenciar os riscos envolvidos em manter em segurança os ativos de informações (incluindo dados do cliente) gerenciados por nossa organização. Dando aos nossos clientes a confiança de que nós, como organização, empregamos padrões internacionais no gerenciamento ativo de dados empresariais e segurança de informações.</p>
                  <h4>O que é a ISO 27001?</h4>
                  <p>A ISO 27001/IEC 27001 é um conjunto de padrões que ajudam as organizações a manter em segurança seus ativos de informações (incluindo sistemas de informações e processos comerciais internos). Esses padrões internacionais descrevem os requisitos para um sistema de gerenciamento de informações que coloca a segurança das informações sob controle do gerenciamento e fornece um conjunto de requisitos específicos que permitem às organizações fazer exatamente isso.</p>
                  <p>A certificação ISO/IEC 27001 demonstra que uma organização definiu e implementou processos de melhores práticas de segurança de informações. </p>
                  <p><strong>As organizações devem se responsabilizar por: </strong></p>
                  <ul>
                    <li>O exame sistemático dos riscos de segurança das informações da organização, levando em consideração as ameaças, vulnerabilidades e impacto potencial;</li>
                    <li>A criação e implementação de um conjunto coerente e abrangente de controles de segurança de informações e/ou outras formas de tratamento de riscos (por exemplo, prevenção ou transferência de riscos) para abordar riscos considerados inaceitáveis;</li>
                    <li>A adoção de um processo abrangente de gerenciamento que garanta que os controles de segurança de informações continuem a atender aos requisitos de segurança de informações da organização continuamente. </li>
                  </ul>
                  <h4>O que a ISO 27001 significa para nossos clientes?</h4>
                  <strong>Ao utilizar os serviços da ExpertSender, você trabalha com uma organização que: </strong>
                  <ul>
                    <li>Protege-se contra ataques cibernéticos e toma as medidas necessárias para proteger você.</li>
                    <li>Implementou controles de segurança adequados e apropriados que ajudam a proteger as informações e enviar campanhas de marketing em conformidade com requisitos regulamentares, como o RGPD. </li>
                    <li>Realiza avaliações regulares e auditorias internas do SGSI para garantir sua melhoria contínua.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
          
          <div className="columns is-centered">
            <div className="post__contentWrap column">
              <hr className="mar-b-3" />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-en-US.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 EN
              </a>
              <br />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-pl-PL.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 PL
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
